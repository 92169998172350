<template>
  <q-tr
      :props="data"
      class="clickable"
      @dblclick="onRowDblClick(data.row)"
  >
    <!--    <td class="q-td text-left">-->
    <!--      <q-checkbox-->
    <!--        size="xl"-->
    <!--        :model-value="!!items.find(({id}) => id === data.row.id)"-->
    <!--        color="amber-6"-->
    <!--        @click="handleCheck(data.row)"-->
    <!--      />-->
    <!--    </td>-->

    <q-td
        key="image"
        :props="data"
        auto-width
    >
      <div class="text-center">
        <img
            :src="getRowImage(data.row)"
            style="width: 80px; height: 70px; object-fit: contain;"
            @error="onImageLoadFailure"
        >
      </div>

      <div class="text-center">
        <q-btn
            :label="$t(data.row._embedded?.productOffer?.type || '')"
            style="min-width: 120px;"
            color="light-blue-9"
            icon-right="visibility"
            size="sm"
            class="q-mt-sm block full-width text-capitalize"
            no-caps
            v-if="data.row._embedded?.productOffer?.id"
            @click="open"
        />

        <q-btn
            :label="$t(`Change`)"
            style="min-width: 120px;"
            color="warning"
            icon-right="warning"
            size="sm"
            class="q-mt-sm block full-width text-capitalize"
            no-caps
            v-if="isItemOfferChangeAllowed(data.row)"
            @click="searchOffer"
        />
      </div>
    </q-td>

    <q-td
        key="offer"
        :props="data"
        style="max-width: 300px; word-wrap: break-word; white-space: normal !important;"
    >
      <div class="text-subtitle1" v-if="data.row._embedded.productOffer">
        {{ `${data.row._embedded.productOffer.name} (${data.row._embedded.productOffer.id})` }}
      </div>

      <div class="text-caption" v-if="data.row._embedded.productOffer">{{ data.row._embedded.productOffer.sku }}</div>

      <div v-if="data.row._embedded.productOffer">{{ data.row._embedded.productOffer.type }}</div>
    </q-td>

    <q-td
        key="id"
        :props="data"
        style="max-width: 160px; word-wrap: break-word; white-space: normal !important;"
    >
      <div class="text-subtitle1">
        {{ `${data.row._embedded?.document?.id}` }}
      </div>

      <div class="text-caption">{{
          $moment(data.row._embedded?.document?.created).format(appOptions.formats.fullDate)
        }}
      </div>

      <q-badge
          v-if="data.row._embedded?.document"
          :color="stateColors[data.row._embedded?.document?.state]"
          text-color="dark"
          class="q-pa-sm"
      >
        {{ $t(data.row._embedded?.document?.state) }}
      </q-badge>

      <div>{{ data.row._embedded?.document?.comment }}</div>

    </q-td>


    <q-td
        key="state"
        :props="data"
        auto-width
    >
        <div class="text-caption">{{
            'Created: '
            }}
        </div>
        <div class="text-caption">{{
            $moment(data.row.created).format(appOptions.formats.fullDate)
            }}
        </div>
        <div class="text-caption">{{
            'Updated: '
            }}
        </div>
        <div class="text-caption">{{
            $moment(data.row.updated).format(appOptions.formats.fullDate)
            }}
        </div>
      <super-dropdown :model="dropdownModel"/>
      <!--      <q-badge-->
      <!--          :color="stateColors[data.row.state]"-->
      <!--          text-color="dark"-->
      <!--          class="q-pa-sm"-->
      <!--      >-->
      <!--        {{ $t(data.row.state) }}-->
      <!--      </q-badge>-->
    </q-td>

    <q-td
        key="shop"
        :props="data"
        auto-width
    >
      <div v-if="shop">
        <strong>
          {{ shop }}
        </strong>
      </div>

      <div v-else>
        {{ '--' }}
      </div>
    </q-td>

    <q-td
        key="price"
        :props="data"
        auto-width
    >
      <strong v-if="!data.row.price">
        -
      </strong>

      <strong v-else>
        {{ data.row.price }}
      </strong>
    </q-td>

    <q-td
        key="items"
        :props="data"
        auto-width
    >
      <!-- <q-btn
        size="md"
        color="light-blue-9"
        round
        dense
        :icon="data.expand ? 'expand_less' : 'expand_more'"
        @click="data.expand = !data.expand"
      /> -->
      <span>
        <table class="table table-valign-middle">
          <thead>
            <tr>
              <th>{{ $t('Status') }}</th>

              <th>{{ $t('Amount') }}</th>

            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <label :class="`q-py-xs q-px-sm rounded bg-positive text-caption text-white`">
                  {{ $t('Expected') }}
                </label>
              </td>

              <td>
                <strong>{{ data.row.quantityExpected || 0 }}</strong>
              </td>

            </tr>
            <tr>
              <td>
                <label :class="`q-py-xs q-px-sm rounded bg-primary text-caption text-white`">
                  {{ $t('Received') }}
                </label>
              </td>

              <td>
                <strong>{{ data.row.quantityReal || 0 }}</strong>
              </td>

            </tr>
            <tr>
              <td>
                <label :class="`q-py-xs q-px-sm rounded bg-dark text-caption text-white`">
                  {{ $t('Allocated') }}
                </label>
              </td>

              <td>
                <strong>{{ data.row.quantityPlace || 0 }}</strong>
              </td>

            </tr>
          </tbody>
        </table>
      </span>
    </q-td>

    <q-td
        key="sku"
        :props="data"
        style="min-width: 200px;"
    >
      <q-input
          standout="bg-teal text-white"
          class="q-mb-xs"
          :model-value="data.row.sku"
          :label="$t('Barcode')"
          :disable="true"
          @update:model-value="updateStorageItem('sku', $event)"
          @keydown.space="(event) => event.preventDefault()"
      />

      <q-input
          standout="bg-teal text-white"
          class="q-mb-xs"
          type="text"
          :disable="true"
          :model-value="data.row.trackingNumber"
          :label="$t('Tracking number')"
          @update:model-value="updateStorageItem('trackingNumber', $event)"
      />

      <form-builder
          :schema="dateSchema"
      />

      <q-input
          standout="bg-teal text-white"
          class="q-mb-xs"
          type="text"
          :disable="true"
          :model-value="data.row.batch"
          :label="$t('Batch')"
          @update:model-value="updateStorageItem('batch', $event)"
      />
    </q-td>

    <q-td
        key="actions"
        style="max-width: 120px"
        :props="data"
    >
      <q-btn-dropdown
          class="q-ml-sm"
          size="lg"
          flat round dense
          no-icon
          dropdown-icon="more_vert"
      >
        <q-list>
          <q-item clickable v-close-popup>
            <q-item-section @click="handleClickWhere">
              <q-item-label>
                {{ 'Where?' }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup>
              <q-item-section @click="handleClickHistory">
                  <q-item-label>
                      {{ 'History' }}
                  </q-item-label>
              </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </q-td>

  </q-tr>

  <q-dialog v-model="historyModal">
      <q-card style="min-width: 65vw">
          <q-card-section class="row items-center q-pb-none">
              <div class="text-h6">
                  {{ $t('History') }}
              </div>

              <q-space/>

              <q-btn v-close-popup icon="close" flat round dense/>
          </q-card-section>

          <q-card-section v-if="data.row.id">
              <history
                      :id="data.row.id"
                      :entity="'Orderadmin\\Storage\\Entity\\Movement\\Acceptance\\Item'"
              />
          </q-card-section>

          <q-card-section v-else class="alert alert-warning">
              {{ $t('Acceptance item not exist!') }}
          </q-card-section>
      </q-card>
  </q-dialog>
  <acceptance-allocation-modal ref="acceptanceAllocationModal"/>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import SuperDropdown from '../super-dropdown/SuperDropdown.vue'
import AcceptanceAllocationModal from '@/apps/app/components/modals/AcceptanceAllocationModal.vue'
import History from "@/apps/app/components/history/history.vue";

export default {
  name: 'InboundItemsRow',
  emits: ['check', 'dblclick', 'open', 'search-offer', 'acceptance-change'],
  components: {
    History,
    AcceptanceAllocationModal,
    SuperDropdown
  },
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    },
    data: {
      type: Object,
      required: true,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      shop: null,
      historyModal: false,
      stateColors: {
        new: 'info',
        confirmed: 'yellow',
        checking: 'warning',
        complete: 'success',
        deleted: 'danger',
        preset: 'warning',
        archived: 'dark text-white',
        closed: 'dark text-white',
        error: 'negative'
      },
      fallbackImage: 'assets/img/fallback-image/package.png',
      stateName: {
        normal: 'On hand',
        booked: 'Booked',
        blocked: 'Blocked',
        new: 'Received'
      },
      itemStates: {
        new: 'grey-3',
        blocked: 'danger',
        booked: 'danger',
        deleted: 'danger',
        shipped: 'green text-white',
        normal: 'success',
        expected: 'green text-white',
        missing: 'grey',
        error: 'negative'
      },
      offerStates: [
        {
          buttons: [
            { id: 'normal', title: 'Active', transitions: ['inactive', 'blocked'], color: 'positive' },
            { id: 'inactive', title: 'Inactive', transitions: ['normal', 'blocked'], color: 'grey' },
            { id: 'blocked', title: 'Blocked', transitions: ['normal'], color: 'red-4' },
            { id: 'deleted', title: 'Deleted', transitions: [], color: 'danger' }
          ]
        }
      ],
      acceptanceItemStatuses: [
        {
          buttons: [
            { id: 'new', title: 'New', transitions: ['error' , 'confirmed'], color: 'info' },
            { id: 'confirmed', title: 'Confirmed', transitions: ['error'], color: 'yellow' },
            { id: 'checking', title: 'Checking', transitions: ['error'], color: 'warning' },
            { id: 'complete', title: 'Complete', transitions: ['error'], color: 'success' },
            { id: 'deleted', title: 'Deleted', transitions: [], color: 'danger' },
            { id: 'error', title: 'Error', transitions: ['new', 'confirmed'], color: 'negative text-white' },
            { id: 'preset', title: 'Preset', transitions: [], color: 'warning' },
            { id: 'archived', title: 'Archived', transitions: [], color: 'dark text-white' },
            { id: 'closed', title: 'Closed', transitions: [], color: 'dark text-white' }
          ]
        }
      ]
    }
  },
  mounted () {
    if (this.data.row && this.data.row._embedded && this.data.row._embedded.productOffer && this.data.row._embedded.productOffer._embedded && this.data.row._embedded.productOffer._embedded.virtualShop) {
      this.shop = this.data.row._embedded.productOffer._embedded.virtualShop._links.self.href.split('/').pop()
    } else if (this.data.row && this.data.row._embedded && this.data.row._embedded.productOffer && this.data.row._embedded.productOffer._embedded && this.data.row._embedded.productOffer._embedded.shop && !shop) {
      this.shop = this.data.row._embedded.productOffer._embedded.shop._links.self.href.split('/').pop()
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
      'isSupervisior',
      'isAdministrator'
    ]),
    dateSchema () {
      return {
        groups: [
          {
            fields: [
              {
                type: 'date',
                value: this.data.row.expires,
                field: 'expires',
                label: this.$t('Expiration Date'),
                max: '3000-01-01',
                disable: true,
                wrapperStyleClasses: 'q-pb-xs',
                onError: () => {
                  this.addErrorNotification(`${this.$t('Maximum date is')} 3000-01-01`)
                },
                onChange: (value) => {
                  // eslint-disable-next-line vue/no-mutating-props
                  this.data.row.expires = value
                  // eslint-disable-next-line vue/no-mutating-props
                  this.data.row.isUpdated = true
                  this.$emit('change', this.data.row)
                }
              }
            ]
          }
        ]
      }
    },
    schema () {
      const value = this.offerStates[0].buttons.find(({ id }) => id === this.data.row.state) || {
        id: 'normal',
        title: 'Active'
      }
      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: value.color || 'default',
        disabledLabels: true,
        editableFields: false,
        onClick: (state) => {
          const query = {
            filter: [
              { type: 'eq', field: 'shop', value: this.data.row._embedded.shop.id }
            ]
          }

          this.$service.offer.save({ state }, this.data.row.id, this.data.row._embedded.shop.id, {}, query)
              .then(item => {
                this.upsertOffer(item)
              })
        },
        value,
        options: this.offerStates
      }

      return option
    },
    disableStatusesDropdown () {
      if (this.isSupervisior || this.isAdministrator) {
        return false
      }
      return true
    },
    dropdownModel () {
      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: this.acceptanceItemStatuses[0].buttons[1].color,
        editableFields: false,
        disabled: this.disableStatusesDropdown,
        disabledLabels: true,
        onClick: (state) => {
          this.data.row.state = state

          return this.$service.acceptanceItem.save({ state: state }, this.data.row.id)
        },
        value: this.acceptanceItemStatuses[0].buttons[0],
        options: this.acceptanceItemStatuses
      }

      this.acceptanceItemStatuses.find(group => {
        let status = group.buttons.find(btn => {
          return btn.id === this.data.row.state
        })

        if (status) {
          option.color = status.color
          option.value = status
        }

        return status
      })

      return option
    },
  },
  methods: {
    ...mapMutations([
      'upsertOffer'
    ]),
    onRowDblClick () {
      this.$emit('dblclick', this.data.row)
    },
    handleCheck () {
      this.$emit('check', this.data.row)
    },
    onImageLoadFailure (e) {
      e.target.src = 'assets/img/fallback-image/package.png'
    },
    getRowImage (row) {
      return row._embedded.productOffer && row._embedded.productOffer.image ? row._embedded.productOffer.image : this.fallbackImage
    },
    open () {
      this.$emit('open', this.data.row)
    },
    isItemOfferChangeAllowed (row) {
      return !row._embedded?.productOffer?.id || row._embedded?.acceptance?.state === 'new'
    },
    searchOffer () {
      this.$emit('search-offer', this.data.row)
      this.$emit('acceptance-change', this.data.row._embedded?.acceptance)

    },
    updateStorageItem (item) {
      return this.$refs.acceptanceItems.save(item)
    },
    handleClickHistory () {
      this.historyModal = true
    },
    handleClickWhere () {
      if (this.data.row?._embedded?.acceptance) {
        this.$service.acceptance.get(this.data.row._embedded?.acceptance?.id)
            .then(item => {
              this.$refs.acceptanceAllocationModal.open(item, this.data.row)
            })
      }
    },
  }
}
</script>
