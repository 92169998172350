<template>
  <div class="q-pa-md">
    <products-search
        v-if="isSearching"
        :disabled="true"
        :products="acceptanceItemsUpdated"
        :options="option"
        :default-open="openOffersSearch"
        @on-search="handleSearch"
        @on-close-search="closeOffersSearch"
        @submit="handleSubmit"
        @on-item-change="handleItemChange"
    >
    </products-search>
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
            :label="$t('Inbound Inventory')"
            text-class="text-h6"
        />

        <search
            dense
            autoset
            is-expandable
            @submit="handleSearch"
        />

        <q-space/>

        <q-btn
            :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
            text-color="white"
            size="sm"
            class="q-mr-sm"
            :label="filterBtnText"
            no-caps
            unelevated
            @click="openCloseFilters"
        />

        <q-btn
            color="dark"
            text-color="white"
            :label="$t('Refresh')"
            size="sm"
            class="q-mr-sm"
            no-caps
            unelevated
            @click="refreshItems"
        />

      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <filters-collapse
            :is-open="isOpenFilters"
            :options="{
            defaultFilter: serverParams.filter,
            fields: activatedFields,
            values: {
              states: status
            }
          }"
            @submit="handleFiltersSubmit"
            @close="openCloseFilters"
        />
      </q-card-section>


      <q-card-section class="row q-pa-none">
        <status-filter
          class="hide-on-mobile"
          :outside-selected-status="selectedStatusFilter"
          :statuses="status"
          @on-change="onStatusFilter"
        />
        <div class="col">
          <q-table
              style="height: calc(100vh - 130px);"
              class="sticky-header-table"
              row-key="id"
              :rows-per-page-label="$t('Rows per page')"
              :rows="acceptanceItems"
              :columns="columns"
              v-model:pagination="pagination"
              :loading="acceptanceItemsLoading"
              :filter="filter"
              :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
              v-model:selected="items"
              virtual-scroll
              binary-state-sort
              flat
              @request="onRequest"
          >
              <template v-slot:loading>
                <q-inner-loading
                    showing
                    color="primary"
                />
              </template>

              <!--          <template v-slot:header-selection="props">-->
              <!--            <q-checkbox-->
              <!--                size="xl"-->
              <!--                :model-value="items.length > 0"-->
              <!--                color="amber-6"-->
              <!--                @click="handleSelectAll(props)"-->
              <!--            />-->
              <!--          </template>-->

            <template v-slot:body="props">
              <inbound-items-row
                  :data="props"
                  :items="items"
                  @dblclick="onRowDblClick"
                  @check="handleCheck"
                  @open="handleShowInfo"
                  @search-offer="handleOfferSearch"
                  @acceptance-change="acceptanceChange"
              />
            </template>
          </q-table>
        </div>
      </q-card-section>
    </q-card>
    <offer-modal ref="offerModal" @submit="handleOfferSubmit"/>
    <sticky-bottom-header
        :is-loading="isSaveLoading"
        :is-active="hasChange"
        @back="handleDiscard"
        @save="save"
    />
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Mixins
import TableMixin from '../../components/global/TableMixin'

// Components
import Search from '../../components/search/Search'
import InboundItemsRow from '../../components/tables/InboundItemsRow.vue'
import OfferModal from '../../components/modals/OfferModal.vue'
import ProductsSearch from '../../components/products/ProductsSearch.vue'
// import ItemsRow from '../../components/items-table/ItemsRow.vue'
// Utils
import { buildQuery } from '../../utils/query-utils'
import StatusFilter from '@/apps/app/components/filters/StatusFilter'
import FiltersCollapse from '@/apps/app/components/filters/FilterCollapse'
import _ from 'lodash'

export default {
  name: 'InboundInventory',
  components: {
    Search,
    FiltersCollapse,
    InboundItemsRow,
    StatusFilter,
    OfferModal,
    ProductsSearch,
    // ItemsRow
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      fallbackImage: 'assets/img/fallback-image/package.png',
      columns: [
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'left'
        },
        {
          label: this.$t('Product'),
          name: 'offer',
          align: 'left'
        },
        {
          label: this.$t('Purchase order'),
          name: 'id',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Store'),
          name: 'shop',
          align: 'left'
        },
        {
          label: this.$t('Price'),
          name: 'price',
          align: 'left'
        },
        {
          label: this.$t('Quantity'),
          name: 'items',
          align: 'center'
        },
        {
          label: this.$t('Sku'),
          name: 'sku',
          align: 'center'
        },
        {
          label: this.$t(''),
          name: 'actions',
          align: 'left'
        }
      ],
      isNeededWarehouse: false,
      itemStates: {
        new: 'grey-3',
        blocked: 'danger',
        booked: 'danger',
        deleted: 'danger',
        shipped: 'green text-white',
        normal: 'success',
        expected: 'green text-white',
        missing: 'grey'
      },
      isOpenFilters: false,
      status:  [
        {
          id: 1,
          group: 'Default',
          color: 'grey-5',
          buttons: [
            {
              id: 'new',
              title: 'New',
              color: 'info',
              editableField: false,
              transitions: []
            },
            {
              id: 'confirmed',
              title: 'Confirmed',
              color: 'yellow',
              editableField: false,
              transitions: []
            },
            {
              id: 'deleted',
              title: 'Deleted',
              color: 'dark text-white',
              editableField: false,
              transitions: []
            }
          ]
        },
      ],
      activatedFields: [
        'id',
        'sku',
        'batch',
        'expires',
        'state',
        'warehouse',
        'documentId',
        'productOffer',
        'trackingNumber'
      ],
      items: [],
      openOffersSearch: false,
      acceptance: {},
      acceptanceItemsUpdated: [],
      updatedItems: {},
      option: {},
      isSearching: false,
      isSaveLoading: false,
      hasChange: false,
    }
  },
  computed: {
    ...mapGetters([
      'acceptanceItems',
      'acceptanceItemsItemsNumber',
      'acceptanceItemsLoading',
      'hasBaseAccess'
    ]),
    products () {
      // Transform items to objects from which I can revert them after submit
      return this.items.map(x => {
        return {
          ...x,
          ...x._embedded.productOffer,
          barcode: x.sku,
          _embedded: {
            ...x._embedded,
            item: { ...x }
          }
        }
      })
    },
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
          ? this.$t('Filtered: ' + this.acceptanceItemsItemsNumber)
          : this.$t('Filter')
    },
    isDisabledAddToOrder () {
      // Order can be created only with acceptanceItems from one shop
      return this.items.length <= 0 || this.items.find(x => x._embedded.shop.id !== this.items[0]._embedded.shop.id)
    },
    selectedStatusFilter () {
      const status = (this.serverParams.filter || []).find(filter => {
        return filter.field === 'state'
      })

      if (status && status.values) {
        return status.values
      }

      return status
          ? [status.value]
          : []
    },
    statuses () {
      return this.status.reduce((acc, group) => {
        return [...acc, ...group.buttons]
      }, [])
    },
  },
  mounted () {
    this.loadDefaultItems()
  },
  unmounted() {
    this.setAcceptanceItems([])
  },
  methods: {
    ...mapActions([
      'loadAcceptanceItems',
      'loadAcceptance',
      'saveAcceptance',
      'loadShop'
    ]),
    ...mapMutations([
      'setOffer',
      'setOffers',
      'updateAcceptance',
      'setAcceptanceItems',
      'setAcceptance',
      'upsertAcceptance'
    ]),
    handleSearch (search) {
      return this.onRequest({ pagination: { page: 1, search } })
    },
    handleAddToOrder () {
      this.$refs.orderModal.show(this.items.map(item => ({ ...item, count: 1 })))
    },
    openCloseFilters () {
      this.isOpenFilters = !this.isOpenFilters
    },
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    toggleFilters () {
      this.isOpenFilter = !this.isOpenFilter
    },
    showReports () {
      this.$refs.warehouseReports.open()
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      if (query.search && query.search[query.search.length - 1] !== '*' && query.search[query.search.length - 2] !== ':' && !query.search.includes('%')) {
        query.search += query.search[query.search.length - 1] === ':'
            ? '*'
            : ':*'
      }
      if (query.filter) {
        query.filter.map((e , i) => {
          if (e.field === 'batch') {
            e.type = 'like'
            e.value = `%${e.value}%`
          }

          return e
        })
      }

      this.updateParams(query)
      return this.loadAcceptanceItems(this.serverParams)
          .then(({ page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }
          })
          .catch(err => {
            if (err.message === 'No warehouses found') {
              this.isNeededWarehouse = true
            }
          })
    },
    handleSelectAll (props) {
      props.selected = !props.selected
    },
    onRowDblClick (acceptanceItem) {
      this.$router.push(`/inbound/purchase-orders/entity/${acceptanceItem._embedded.acceptance.type}/${acceptanceItem._embedded.acceptance.id}`)
    },
    handleCheck (item) {
      let hasItem = false

      this.items = this.items.filter(x => {
        if (x.id === item.id) {
          hasItem = true
          return false
        }

        return true
      })

      if (!hasItem) {
        this.items = [item, ...this.items]
      }
    },
    loadDefaultItems () {
      return this.onRequest({ pagination: {} })
    },
    onStatusFilter (values) {
      const query = {
        per_page: this.serverParams.perPage || 25,
        page: 1,
        'order-by': [
          {
            type: 'field',
            field: 'created',
            direction: 'desc'
          }
        ],
        filter: [
          ...(this.serverParams.filter || [])
        ]
      }

      query.filter = query.filter.filter(val => {
        return val.type !== 'in' && val.field !== 'state'
      })

      if (values.length > 0) {
        query.filter.push({ type: 'in', field: 'state', values })
      }

      this.updateParams(query)
      this.loadAcceptanceItems(query)
    },
    handleFiltersSubmit (filter) {
      this.isOpenFilter = false
      return this.onRequest({ pagination: { filter, page: 1 } })
    },
    handleShowInfo (item) {
      if (item._embedded.productOffer._embedded.virtualShop) {
        const shopID = item._embedded.productOffer._embedded.virtualShop._links.self.href.split('/').pop()

        const options = {
          warning: this.$t('All changes won\'t be affected on the already existing documents and received products!')
        }

        this.loadShop(shopID)
            .then(e => {
              const product = {
                ...item,
                ...item._embedded.productOffer,
                _embedded: {
                  shop: e
                }
              }
              this.$refs.offerModal.open(product, options)
            })
      } else {
        const shopID = item._embedded.productOffer._embedded.shop._links.self.href.split('/').pop()

        const options = {
          warning: this.$t('All changes won\'t be affected on the already existing documents and received products!')
        }

        this.loadShop(shopID)
            .then(e => {
              const product = {
                ...item,
                ...item._embedded.productOffer,
                _embedded: {
                  shop: e
                }
              }
              this.$refs.offerModal.open(product, options)
            })
      }
    },
    handleOfferSubmit (productOffer) {

      this.items = this.items.map(x => {
        if (x._embedded.productOffer && `${x._embedded.productOffer.id}` === `${productOffer.id}`) {
          return {
            ...x,
            _embedded: {
              ...x._embedded,
              productOffer
            }
          }
        }

        return x
      })
    },
    handleOfferSearch (item) {
      this.openOffersSearch = true

      this.options(this.acceptance, item)

    },
    closeOffersSearch () {
      this.openOffersSearch = false

      this.option.item = null
    },
    handleSubmit (data) {
      let test = this.acceptance
      test = {
        ...this.acceptance,
        _embedded: { ...this.acceptance._embedded, warehouse: data.warehouse }
      }
      this.updateAcceptance(test)
      this.hasChange = true
      this.acceptanceItemsUpdated = this.convertProductsToItems(data.products)
      this.setAcceptanceItems(_.cloneDeep(data.products))
      this.isEnabledSaveMessage = true
      this.pagination.rowsNumber = this.acceptanceItemsUpdated.length
      this.isSearching = false
      return this.acceptanceItemsUpdated
    },
    handleItemChange (data) {
      let i = this.acceptanceItemsUpdated.findIndex(x => x.id === data.item)

      this.acceptanceItemsUpdated[i] = { ...this.acceptanceItemsUpdated[i], ...data.product }
      this.acceptanceItemsUpdated[i].isUpdated = true

      this.hasChange = true
      this.isEnabledSaveMessage = true
    },
    acceptanceChange (acceptance) {
      this.loadAcceptance(acceptance.id)
          .then(result => {
            this.acceptance = result
            this.getTheItems()
            this.options(this.acceptance)
            this.isSearching = true
          })

    },
    options (acceptance, item = {}) {
      return this.option = {
        hasBarcode: true,
        batch: acceptance && acceptance.type === 'batch',
        isUnique: acceptance && acceptance.type === 'palletized'
            ? ['grouped', 'pallet']
            : [],
        disabledWarehouse: acceptance && acceptance._embedded && acceptance._embedded.warehouse && this.acceptanceItemsUpdated.length > 0,
        allShops: true,
        noWarehouseFilter: true,
        onlyOneShop: true,
        defaultValues: {
          shop: this.option?.item?.embedded?.shop,
          warehouse: acceptance && acceptance._embedded && acceptance._embedded.warehouse
        },
        allowNoOffer: true,
        openOffersSearch: this.openOffersSearch,
        item: !this.option.item ? item : this.option.item
      }
    },
    getTheItems (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      this.isLoading = true

      if (!query.filter) {
        query.filter = []
      }
      query.filter.push({ field: 'document', type: 'eq', value: this.acceptance.id })

      return this.$service.acceptanceItem.getAllForAcceptance(query, this.acceptance.state)
          .then(({ items, totalPages, page, totalItems }) => {

            if (!this.updatedItems[page - 1]) {
              this.updatedItems[page - 1] = []
            }

            this.updatedItems[page - 1] = [...this.updatedItems[page - 1], ...this.acceptanceItemsUpdated.filter(x => x.isUpdated)]

            this.pagination = {
              ...this.pagination,
              // Their is a problem with page in response. It is always 1 so we set it manually
              page: query.page || 1,
              rowsNumber: totalItems
            }

            this.acceptanceItemsUpdated = this.updatedItems[page]

                ? items.map(x => {
                  const updatedItem = this.updatedItems[page].find(i => i.id === x.id && i.originalSku === x.originalSku)
                  return updatedItem || x
                })
                : items

            return { items, totalPages, page, totalItems }
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    convertProductsToItems (products) {
      let productsResult = products.map(x => {
        const item = {
          ...x,
          originalSku: x.barcode,
          sku: x.barcode,
          barcode: undefined,
          dimensions: this.getDimensions(x.dimensions),
        }

        if (x._embedded?.productOffer) {
          item.productOffer = {
            id: x._embedded?.productOffer.id,
            shop: x._embedded?.shop?.id
          }
        }

        if (x.barcode === undefined) {
          item.sku = this.acceptance.type === 'products'
              ? `S/I/PO${item._embedded.productOffer.id}*`
              : `S/I/${item._embedded.productOffer.id}*`
        }

        const foundedItem = this.acceptanceItemsUpdated.find(i => {
          return i.id == item.id && i.originalSku == x.originalSku && (i._embedded.productOffer || {}).id == (item._embedded.productOffer || {}).id
        })

        // if (!foundedItem && item._embedded.productOffer) {
        //   item._embedded.productOffer = x
        //   item._embedded.productOffer.dimensions = this.getDimensions(item._embedded.productOffer.dimensions)
        // }

        // Check for difference between existed item and submited one
        // Clone founded item to convert it from proxy to object
        item.isUpdated = !foundedItem || !!foundedItem.isUpdated || JSON.stringify(item) !== JSON.stringify({ ...foundedItem })
        return item
      })

      return productsResult
    },
    getDimensions (dimensions) {
      if (!dimensions) {
        return { x: 0, y: 0, z: 0 }
      }

      if (typeof dimensions === 'string') {
        return JSON.parse(dimensions)
      }

      return dimensions
    },
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    },
    save () {
      this.isSaveLoading = true
      this.saveAcceptance()
          .then(item => {
            this.upsertAcceptance(item)
            this.setAcceptance(item)
            return this.acceptanceItemsUpdated = [...this.acceptanceItemsUpdated, item]
          })
          .then((errorOrItems) => {
            this.hasChange = false
            this.isEnabledSaveMessage = false
            if (!this.$route.params.id && typeof errorOrItems !== 'boolean') {
              this.handleBack()
            }
          })
          .finally(() => {
            this.isSaveLoading = false
          })
    },
  }

}
</script>
